@import "~antd/dist/antd.css";
/*@import '~antd/dist/antd.dark.css';*/

.logo {
  height: 40px;
  width: auto;
  background: url("./images/Space_Logo_White_100px.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 16px;
  margin: 16px;
}

.ant-layout-sider-children {
  background: #1f1f1f;
}

.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  background: #1f1f1f;
}

.ant-menu-item::after {
  border-right-color: rgb(223, 24, 96);
  border-right: 3;
}

.ant-menu-item::after {
  border-right-color: rgb(223, 24, 96);
  border-right: 3;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: rgb(223, 24, 96);
}

.ant-menu-item-selected:hover {
  background-color: whitesmoke;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

[data-theme="dark"] .site-layout-sub-header-background {
  background: #141414;
}

.ant-btn-primary {
  color: #fff;
  background-color: rgb(223, 24, 96);
  border-color: rgb(223, 24, 96);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn:hover {
  color: white;
  border-color: rgb(223, 24, 96);
  background-color: rgb(229, 79, 128);
}

.ant-btn-secondary {
  color: rgb(223, 24, 96);
  border-color: rgb(223, 24, 96);
  background-color: white;
}

.ant-btn-secondary:hover {
  color: rgb(229, 79, 128);
  border-color: rgb(229, 79, 128);
  background-color: white;
}

.ant-layout-sider-zero-width-trigger {
  background: #1f1f1f;
}

.sparkles {
  background-color: #1f1f1f !important;
}

.ant-menu-item.nav-text {
  color: white;
}

.nav-text {
  color: white;
}

.nav-link {
  color: white;
}

.nav-link:hover {
  color: gray;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: rgb(223, 24, 96);
}

.ant-tabs-nav:hover .ant-tabs-tab-active:hover {
  color: rgb(229, 79, 128);
}

.ant-tabs-ink-bar {
  background-color: rgb(223, 24, 96);
}

.ant-tabs-nav:hover {
  color: rgb(229, 79, 128);
}

.ant-statistic-content {
  color: rgb(223, 24, 96);
  font-size: 12px;
}

.ant-statistic-title {
  margin-bottom: 4px;
  color: black;
  font-size: 14px;
}

.branchLoading {
  text-align: center;
  padding: 9px 50px;
}

/* .ant-table-filter-trigger {
  display: block;
  width: 2.3em;
  right: 100;
  color: #bfbfbf;
  font-size: 12px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
} */
